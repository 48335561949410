import React, { useEffect } from 'react';
import './AboutMissionHistoryTeam.css';
import history from './images/about us - our history.jpg';

import DaveHeadshot from './TeamPics/Dave Circle (1) new.png';
import Cherry from './TeamPics/Cherry Lie Sta Cruz CII site pic.png';
import Christian from './TeamPics/Christian Winters CII site.png';
import Hector from './TeamPics/Hector De La Garza CII site.png';
import John from './TeamPics/John Chihade CII site.png';
import Kelly from './TeamPics/Kelly Wolf CII Site.png';
import Marlon from './TeamPics/Marlon Beltran CII site pic.png';
import Matt from './TeamPics/Matt Wolf CII site.png';
import Tiffany from './TeamPics/Tiffany Puaux Feugere CII site.png';
import Asma from './TeamPics/Asma Bedoui CII site pic.png';
import Carlos from './TeamPics/Carlos Guzman CII site pic.png';
import Derek from './TeamPics/Derek Clever CII site pic.png';
import Tawfig from './TeamPics/Tawfig Chihade CII site pic.png';
import Dominic from './TeamPics/Dominic Frank CII site pic.png';
import Isela from './TeamPics/Isela Sanchez Vargas CII site.png';
import Ronald from './TeamPics/Ronald Pastrana CII site pic.png';
import Wynette from './TeamPics/Wynette Circle CII site.png';

const teamMembers = [
  { name: 'Tawfig Chihade', title: 'Founder', image: Tawfig },
  { name: 'John Chihade', title: 'CEO', image: John },
  { name: 'Derek Clever', title: 'President', image: Derek },
  { name: 'Marlon Beltran', title: 'Purchasing Supervisor', image: Marlon },
  { name: 'Asma Bedoui', title: 'Sales Manager, UAE & Oman', image: Asma },
  { name: 'Hector De La Garza', title: 'Warehouse Manager', image: Hector },
  { name: 'Tiffany Puaux Feugere', title: 'National Accounts Coordinator', image: Tiffany },
  { name: 'Dominic Frank', title: 'Foodservice Category & Country Sun Brand Manager', image: Dominic },
  { name: 'Carlos Guzman', title: 'Sales Manager, LATAM & Caribbean', image: Carlos },
  { name: 'Dave Parr', title: 'Sales Director', image: DaveHeadshot },
  { name: 'Ronald Pastrana', title: 'Sales Manager, KSA, Qatar, Bahrain, Kuwait & the Philippines', image: Ronald },
  { name: 'Isela Sanchez', title: 'Export Label Manager', image: Isela },
  { name: 'Cherry Lie Sta Cruz', title: 'Logistics Supervisor', image: Cherry },
  { name: 'Christian Winters', title: 'Operations & Export Director', image: Christian },
  { name: 'Kelly Wolf', title: 'Human Resources & Office Manager', image: Kelly },
  { name: 'Matt Wolf', title: 'Purchasing & Customer Service Manager', image: Matt },
  { name: 'Wynette Hill', title: 'Documentation Manager', image: Wynette },
];

const teamGroups = {
  row1: [
    { name: 'Tawfig Chihade', title: 'Founder', image: Tawfig },
    { name: 'John Chihade', title: 'CEO', image: John },
    { name: 'Derek Clever', title: 'President', image: Derek },
  ],
  row2: [
    { name: 'Asma Bedoui', title: 'Sales Manager, UAE & Oman', image: Asma },
    { name: 'Carlos Guzman', title: 'Sales Manager, LATAM & Caribbean', image: Carlos },
    { name: 'Cherry Lie Sta Cruz', title: 'Logistics Supervisor', image: Cherry },
    { name: 'Christian Winters', title: 'Operations & Export Director', image: Christian },
  ],
  row3: [
    { name: 'Dave Parr', title: 'Sales Director', image: DaveHeadshot },
    { name: 'Dominic Frank', title: 'Foodservice Category & Country Sun Brand Manager', image: Dominic },
    { name: 'Hector De La Garza', title: 'Warehouse Manager', image: Hector },
    { name: 'Isela Sanchez', title: 'Export Label Manager', image: Isela },
    { name: 'Kelly Wolf', title: 'Human Resources & Office Manager', image: Kelly },
  ],
  row4: [
    { name: 'Marlon Beltran', title: 'Purchasing Supervisor', image: Marlon },
    { name: 'Matt Wolf', title: 'Purchasing & Customer Service Manager', image: Matt },
    { name: 'Ronald Pastrana', title: 'Sales Manager, KSA, Qatar, Bahrain, Kuwait & the Philippines', image: Ronald },
    { name: 'Tiffany Puaux Feugere', title: 'National Accounts Coordinator', image: Tiffany },
    { name: 'Wynette Hill', title: 'Documentation Manager', image: Wynette },
  ]
};


function History() {
  const otherMembers = teamMembers.slice(3).sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const yOffset = -100; // Adjust offset as needed
        const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
      }
    }
  }, []);


  return (
    <div className="about-page">
      {/* About Us Section */}
      <div className="sbanner">
        <h1>ABOUT US</h1>
      </div>

      {/* Our Mission Section */}
      <div id="mission" className="history-content">
        <h2 className="mission-title">Our Mission</h2>
        <p className="mission-text">
          For over 45 years, Chihade International has provided turn-key supply chain solutions for retail grocery store consolidations, restaurant franchise consolidations, foodservice distribution, airline catering, and sales of beef and other proteins. As a family business, we see our customers and vendors as valued friends and are committed to the long-term success of our partners.
        </p>
        <p className="mission-text">
          Our mission is simple, to facilitate outstanding international supply chain management for our customers, vendors, and partners. With over 45 years of experience, we offer product knowledge, cultural awareness, and efficient and effective warehousing, logistics, documentation, and a deep understanding of all aspects of exporting food products around the globe.
        </p>
        <p className="mission-text">
          Our long-term relationships, built on trust, include U.S.-based restaurant franchises and their overseas franchisees, U.S. beef and poultry suppliers, major U.S. manufacturers and their distributors, smaller organic and specialty producers, specialized distributors, and supermarket chains and 3PL partners.
        </p>
      </div>

      <div id="history" className="history-content">
  <h2 className="mission-title">Our History</h2>
  <div className="history-section">
    {/* Image on the left */}
    <div className="history-image-container">
      <img src={history} alt="Our History" />
    </div>
    {/* Text on the right */}
    <div className="history-text">
      <p>
        Tawfig Chihade, the founder of Chihade International, began in the food business as a regional manager for RJR and then moved on to Hunt’s before starting Chihade International in 1979.
      </p>
      <p>
        While the company started off as mainly retail, we have since grown steadily and now provide supply chain expertise in the Franchise, Consolidation, Protein and Foodservice markets.
      </p>
      <p>
        Our extensive history has given us a deep understanding of all aspects of exporting food and supply chain management. From purchasing to shelf-life needs, from labeling and dating requirements to specific country regulations, we utilize our expertise and knowledge of the intricacies of goods export in order to ensure that our customers’ expectations are exceeded.
      </p>
      <p>
        Chihade International’s diverse employees also understand the unique ways of doing business for all of our partner countries and are experts in navigating the complex worlds of international. We incorporate connect cultures as part of our business model and look to align the needs of all of our customers, vendors, and partners.
      </p>
    </div>
  </div>
</div>


      {/* Team Section */}
      <div id="team" className="team">
        <h2>Our Team</h2>

        {/* Row 1 */}
        <div className="team-row">
          {teamGroups.row1.map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} className="team-member-pic" />
              <div className="team-member-info">
                <h3>{member.name}</h3>
                <p>{member.title}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Row 2 */}
        <div className="team-row">
          {teamGroups.row2.map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} className="team-member-pic" />
              <div className="team-member-info">
                <h3>{member.name}</h3>
                <p>{member.title}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Row 3 */}
        <div className="team-row">
          {teamGroups.row3.map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} className="team-member-pic" />
              <div className="team-member-info">
                <h3>{member.name}</h3>
                <p>{member.title}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Row 4 */}
        <div className="team-row">
          {teamGroups.row4.map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} className="team-member-pic" />
              <div className="team-member-info">
                <h3>{member.name}</h3>
                <p>{member.title}</p>
              </div>
            </div>
          ))}
        </div>


      </div>
    </div>
  );
}
export default History;
