import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import logo from "./logo-nobckgrd.png";
// import exportImage from "./export.png";
import exportImage from "./Export.jpg";
import WarehouseImage from './images/home page - warehouse solutions.png';
import productsImage from './images/PRODUCTS PAGE PIC.png'

// import ChihadeVideo from "./ChihadeVideo.mov";
import ChihadeVideo from "../src/ChihadeVideo.mp4";
import Contact from './Contact.js';
import Layout from './Layout.js';
import AboutMissionHistoryTeam from './AboutMissionHistoryTeam.js'
import Export from './Export.js';
import Warehouse from './Warehouse.js'
import Products from './Products.js'
import Privacy from './Privacy.js'
import Claims from './Claims.js'
import Bribery from './Bribery.js';

import './App.css';
// import './Contact.css';
// import './OurMission.css';
// import './History.css';
// import './Team.css';


import { useLocation } from "react-router-dom";

// ScrollToTop Component
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [pathname]);

  return null;
}

function Home() {
  const [isPlaying, setIsPlaying] = useState(true);
  const [videoError, setVideoError] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const playVideo = (e) => {
      // Only play the video if the click is within the video or the control button
      if (
        videoRef.current &&
        (videoRef.current.contains(e.target) || e.target.closest('.video-control'))
      ) {
        if (!isPlaying) {
          videoRef.current.play().catch(e => {
            console.error("Video playback error:", e);
            setVideoError(e.message);
          });
          setIsPlaying(true);
        }
        document.removeEventListener('click', playVideo); // Remove listener after play
      }
    };

    document.addEventListener('click', playVideo);

    return () => {
      document.removeEventListener('click', playVideo);
    };
  }, [isPlaying]);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch(e => {
          console.error("Video playback error:", e);
          setVideoError(e.message);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>



      {/* Content Sections */}
      <section className="content-section">
        <div className="left-content">

        </div>
      </section>

      <div></div>
      <div className="video-banner" style={{ position: 'relative', width: '100%', height: '680px', marginTop: '-200px' }}>
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          preload="auto"
          style={{ width: '100%', height: '130%', objectFit: 'cover' }}
          onError={(e) => {
            console.error("Video error:", e);
            setVideoError(e.message);
          }}
        >
          <source src={ChihadeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Hazel-colored background overlay */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '130%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Darker haze with increased opacity
            zIndex: 1, // Ensures the overlay is above the video but below other content
          }}
        ></div>


        <div
          className="video-overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '130%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2,
          }}
        >
          <h2
            style={{
              color: '#FFFFFF', // White color for better contrast
              fontFamily: '"Playfair Display", Georgia, serif', // Elegant serif font
              fontWeight: 700,
              textAlign: 'center',
              maxWidth: '90%',
              margin: '0 auto',
              lineHeight: 1.2, // Adjusted line height for better spacing
            }}
          >
            <span
              style={{
                display: 'flex',
                fontWeight: 580,
              }}
            >
              <span

                className="responsive-text-1"
              >
                EXPORTING & CONSOLIDATING
              </span>
            </span>
            <span

              className="responsive-text-2"
            >
              U.S. FOOD PRODUCTS
            </span>
            <span

              className="responsive-text-3"
            >
              SINCE 1979
            </span>
          </h2>




          <button
            className="video-control bounce"
            onClick={togglePlay}
            style={{
              position: 'absolute', // Absolute positioning within the overlay
              bottom: '80px', // Place the button near the bottom edge
              left: '39px', // Place the button near the left edge
              backgroundColor: '#DBB829', // Yellow background color
              border: 'none',
              borderRadius: '50%',
              width: '60px',
              height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              transition: 'transform 0.2s', // Smooth transition for hover effect
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'scale(1.1)'; // Scale up on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)'; // Scale back on leave
            }}
          >
            {isPlaying ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
                <path d="M6 4h4v16H6V4zm8 0h4v16h-4V4z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
                <path d="M8 5v14l11-7z" />
              </svg>
            )}
          </button>
        </div>
      </div>




      <section className="content-section">

        <div className="left-content">
          <Link to="/export">
            <img src={exportImage} alt="Export" />
          </Link>
        </div>
        <div className="right-content">
          <h2 className="esection-title">EXPORT SERVICES</h2>
          <p className="esection-text">
            Our team brings in-depth knowledge and expertise to all aspects of the supply chain for food exports. With over forty-five years in the business, we understand the complexities of exporting to all of our partner countries, including customs clearing, documentation requirements, bringing products to market, and supporting customers with promotions.
          </p>
        </div>


      </section>

      <section className="content-section">

        <div className="right-content">
          <h2 className="section-title">WAREHOUSE SOLUTIONS</h2>
          <p className="section-text">
            With our modern 64,000 sq. ft. facility, we have the ability to handle all of our customers’ food export needs. Whether dry, chilled, or frozen, our experts in food handling maintain close control over quality and time-sensitive products. We have 7 lines running to insure product has appropriate language labels and ink-jetting to meet country requirements.
          </p>
        </div>
        <div className="left-content">
          <Link to="/warehouse">
            <img src={WarehouseImage} alt="Warehouse" />
          </Link>
        </div>


      </section>


      <section className="content-section">

        <div className="left-content">
          <div className='productImg'>
            <Link to="/products">
              <img src={productsImage} alt="Products" />
            </Link>
          </div>
        </div>
        <div className="right-content">
          <h2 className="section-title">PRODUCTS</h2>
          <p className="section-text">
            We work with over 300 vendors and supply thousands of products to our foodservice, franchise, and retail partners.
          </p>
        </div>


      </section>
    </>
  );
}

//new

function App() {
  return (
    <>


      <ScrollToTop /> {/* Add this */}
      <Routes>

        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutMissionHistoryTeam />} />
          <Route path="contact" element={<Contact />} />
          <Route path="export" element={<Export />} />
          <Route path="warehouse" element={<Warehouse />} />
          <Route path="products" element={<Products />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/claims" element={<Claims />} />
          <Route path="/bribery" element={<Bribery />} />
        </Route>
      </Routes>
    </>
  );
}
// function App() {
//   return (
//     <Routes>
//       <Route path="/" element={<Layout />}>
//         <Route index element={<Home />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/our-mission" element={<OurMission />} />
//         <Route path="/history" element={<History />} />
//         <Route path="/team" element={<Team />} />
//         <Route path="/about" element={<AboutMissionHistoryTeam />} />
//         {/* <Route path="/products/franchise" element={<Franchise />} />
//         <Route path="/products/proteins" element={<Proteins />} />
//         <Route path="/products/foodservice" element={<Foodservice />} />
//         <Route path="/products/retail" element={<Retail />} />
//         <Route path="/products/country" element={<Country />} /> */}
//       </Route>
//     </Routes>
//   );
// }

export default App;
