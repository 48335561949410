import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import phone from '../src/phone.png';
import email from '../src/email.png';
import address from '../src/map.png';
import './Contact.css'


const ContactUs = () => {
  const [scrolled, setScrolled] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    businessName: '',
    email: '',
    phone: '',
    message: ''
  });

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formData, 'YOUR_USER_ID')
      .then((result) => {
        alert('Message sent successfully!');
        setFormData({
          name: '',
          businessName: '',
          email: '',
          phone: '',
          message: ''
        });
      }, (error) => {
        alert('Failed to send message, please try again.');
      });
  };

  return (
    <div className="contact-page">

      <div className="contact-container">
        <div className="contact-info-column">
          <h2>Contact Us</h2>
          <div className="info-item">
            <img src={address} alt="Address" />
            <p>
              Chihade International, Inc.<br />
              1025 Raco Court Suite D,<br />
              Lawrenceville, GA 30046 USA
            </p>
          </div>
          <div className="info-item">
            <img src={phone} alt="Phone" />
            <p>+1-404-292-5033</p>
          </div>
          <div className="info-item">
            <img src={email} alt="Email" />
            <p>info@chihadeintl.com</p>
          </div>
        </div>

        <div className="contact-form-column">
          <h2>Email Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="YOUR NAME (REQUIRED)"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="businessName"
                placeholder="BUSINESS NAME"
                value={formData.businessName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="YOUR EMAIL (REQUIRED)"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="phone"
                placeholder="PHONE"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <textarea
                name="message"
                placeholder="YOUR MESSAGE"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;