import React, { useEffect } from 'react';
import './Export.css';
import logImg from './images/export services - logistics & export services.png';
import franceImg from './images/export services - franchise services.png';
import distImg from './images/export services - distributor partners.png';
import retailImg from './images/export services - retail & supermarket.png';

function Export() {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const yOffset = -100; // Adjust offset as needed
                const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: yPosition, behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className="export-page">
            {/* Banner Section */}
            <div className="ebanner">
                <h1>EXPORT SERVICES</h1>
            </div>

            {/* Logistics and Export Services Section */}
            <div id="logistics" className="etwo-column-section">
                <div className="column">
                    <h2>LOGISTICS & EXPORT SERVICES</h2>
                    <p>
                        Our team brings in-depth knowledge and expertise to all aspects of the supply chain for food exports. With over forty-five years in the business, we understand the complexities of exporting to all of our partner countries, including customs clearing, documentation requirements, bringing products to market, and supporting customers with promotions.
                    </p>
                    <ul>
                        <li>Financing/Terms</li>
                        <li>Consolidation</li>
                        <li>Logistics & Freight Forwarding</li>
                        <li>Sourcing Special Request</li>
                        <li>Stickering Products for Language Requirements</li>
                        <li>Ink-jet Dating on Products as Necessary</li>
                        <li>Freight Contracts with Major Lanes</li>
                        <li>Cargo Insurance</li>
                        <li>
                            Documentation Requirements & Legalization including Invoice, Packing List, USDA (as needed),
                            Health Certificate, Halal (as needed), Certificates of Origin, and Other Required Documents
                        </li>
                        <li>Warehousing</li>
                    </ul>
                </div>
                <div className="column">
                    <img src={logImg} alt="Logistics & Export Services" />
                </div>
            </div>

            {/* Franchise Services Section */}
            <div id="franchise" className="etwo-column-section">
                <div className="column">
                    <img src={franceImg} alt="Franchise Services" />
                </div>
                <div className="column">
                    <h2>FRANCHISE SERVICES</h2>
                    <p>
                        We are experts in world-wide export solutions for US-based franchises – partnering with over 30 major brands. Working closely with both the US-based franchise and the franchisee, our team facilitates all aspects of food exports, from the initial specification of the correct product through to on-time arrival, import documentation, and customs clearing. We understand the franchisee’s need for consistency across the supply chain, especially for on time arrival and correctly specified food items.
                    </p>
                    <p>
                        Our forty-five plus years of experience with our partner countries ensures a high level of accuracy with
                        import documentation and clearing. As sales can fluctuate, we also provide help with urgent shipments.
                    </p>
                    <p>
                        We also understand the importance of cost in the supply chain and as a family business, we are able to
                        offer flexible pricing structures.
                    </p>
                    <p>
                        We handle the process from beginning to end:
                    </p>
                    <ul>
                        <li>Sourcing and Ensuring correctly product specifications</li>
                        <li>Managing Product Shelf-Life</li>
                        <li>Correct product labeling</li>
                        <li>Consolidation</li>
                        <li>Shipping & Export Logistics & Documentation</li>
                        <li>Terms</li>
                        <li>On-time solutions (including air and/or urgent)</li>
                    </ul>
                </div>
            </div>

            {/* Distributor Partners Section */}
            <div id="distributor" className="etwo-column-section">
                <div className="column">
                    <h2>DISTRIBUTOR PARTNERS</h2>
                    <p>
                        Chihade International is committed to building long-term partnerships of trust with our distributor
                        partners, extending to end users, retailers, hotels, restaurants, and franchisees. We offer a broad range
                        of programs from proteins to supporting franchisees to organics and everything in between.
                    </p>
                    <p>
                        We also work closely with distributors in order to provide the correct products to customers and
                        maintain exclusivity of certain lines with distributors. We help our distributors improve on their success
                        with product knowledge, sales and marketing support.
                    </p>
                    <p>
                        Many of our partners have been with us since 1979, trusting Chihade International with continued
                        growth.
                    </p>
                </div>
                <div className="column">
                    <img src={distImg} alt="Distributor Partners" />
                </div>
            </div>

            {/* Retail & Supermarket Section */}
            <div id="retail" className="etwo-column-section">
                <div className="column">
                    <img src={retailImg} alt="Retail & Supermarket" />
                </div>
                <div className="column">
                    <h2>RETAIL & SUPERMARKET</h2>
                    <p>
                        We provide supply chain solutions to our retail and supermarket partners with a high level of
                        understanding for the need for a broad range of items in all categories. We work with most of the
                        major USA based manufactures to supply National Branded products in Frozen, Chilled and Dry ranges.
                        We also supply a large variety of organic and specialty vendors to increase the product range and
                        bottom lines of our partners.
                    </p>
                    <p>
                        We represent many brands exclusively and provide ongoing support including help with marketing,
                        promotions and sampling.
                    </p>
                    <p>
                        Our low cost model insures the best pricing for our customer.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Export;