import React, { useEffect } from 'react';
import './Claims.css';

function Claims() {
    return (
        <div className="cexport-page">
            {/* Banner Section */}
  
            <div>
                <h1>CHIHADE INTERNATIONAL, INC. CLAIMS POLICY</h1>

                <p>No claims or discrepancies will be considered if presented to Chihade International, Inc. (CII) more than 2 weeks from when the shipment arrives at the destination port. Any claims that involve anything that could be deemed the fault of the carrier needs to be claimed within 2 days of receipt of goods and noted on all documentation when receiving goods. CII's maximum liability from any claim is the total invoiced amount for product or service.</p>

                <h2>Cargo Insurance</h2>
                <p>Chihade International, Inc. offers cargo insurance at a rate of USD$0.52 per USD$100. There is a $5000 deductible on all insurance-related claims which is covered by the purchaser. The purchaser will be responsible for handling all insurance and insurance-related claims directly with the insurance company. Chihade International, Inc. provides insurance on behalf of the borrower.</p>

                <h2>Claims Against Short Received Product</h2>
                <p>For a claim to be considered, the full receiving list for the container/shipment in question showing which items have been received short and overages must be presented. For consolidated shipments which come through our warehousing facilities, each pallet tag noting items on the pallet and the pallet number from where the product is said to be short shipped must be provided. (CII will maintain pictures and/or video of the loading on each pallet to compare this information). If Chihade International, Inc.’s records indicate that the product was received and shipped, the product will be considered lost or stolen. CII cannot be held responsible for expedited shipment of any short received items.</p>

                <h2>Pricing Discrepancies</h2>
                <p>The original confirmed quote price and the invoice to the customer must be presented. For contract pricing, pricing will change monthly, and pricing charges will be based on actual cost. All price quotes are subject to final confirmation.</p>

                <h2>Demurrage Claims</h2>
                <p>Demurrage charges will only be considered if the B/L has not been provided to the consignee by the last day of free demurrage. Notification to CII that demurrage charges are accruing must be provided by the first day charges start accruing. If CII has sent documents to be legalized within 14 days of B/L date and the embassy is not providing legalization in a timely manner, CII cannot be held liable for demurrage charges. If there are customs issues, the product needs to be moved to a warehousing facility and the container returned to the port to avoid any demurrage charges. CII cannot be held responsible for demurrage accrued due to customs issues. If documents are not being picked up from the bank on Sight Draft, Cash against Documents, or L/C shipments, CII is not responsible for demurrage charges. If documents are being held by CII due to late payment, CII is not responsible for demurrage charges.</p>

                <h2>Product Ingredient Claims</h2>
                <p>CII will make every effort to ensure alcohol, pork, and other banned items, depending on location, do not ship in the container. CII will only be liable for the cost of these products if they ship in error. Both the purchaser and CII are equally at fault on these banned ingredients, and the purchaser must do full due diligence prior to ordering items.</p>

                <h2>Shelf-Life</h2>
                <p>CII will make every effort to provide the best shelf-life possible on products. Any minimal shelf-life upon shipping needs to be addressed by the purchaser upon confirmation of the order. Many products, such as Salad Dressings, Deli Meats, Cereals, Mayonnaise-based products, frozen breads, taco shells, and other products start with less than 6 months shelf-life. Any product arriving with over 1 month shelf-life, but under the customer-required shelf-life, needs to be sold at a discount which will reduce the claim. CII can only be responsible for shelf-life at the time of loading the container and does not guarantee against delays in shipment which can lower the total shelf-life. CII cannot be responsible for delays by customs or any other factors that reduce the shelf-life.</p>
                <p>Some countries will limit shelf-life to less than what the USA allows. Any shelf-life maximums need to be indicated upon confirmation of the order. Once the product arrives at CII warehouse, it will vary from vendor if it is possible to return the product. If maximums are not known prior to order confirmation, it will be the purchaser’s responsibility to pay for any restocking fees associated with returning product or any discounts needed to sell the product.</p>

                <h2>Transit Times – Shipping Schedules</h2>
                <p>CII will make every effort to meet shipping schedules. An order update is typically sent every Friday with shipment status. CII cannot be responsible for air shipping product that does not meet shipping schedules. Orders cannot be canceled if the shipping schedule is not met.</p>

                <h2>Documentation Issues</h2>
                <p>All required documentation needs to be clearly defined by the customer prior to confirming an order. CII will be happy to provide examples of standard documentation, including Invoice, Packing list, B/L, Certificate of Origin, USDA (if applicable), Health Certificate, Halal Certificate (if applicable), Phyto Certificate (if applicable), and any other necessary documentation. Any changes in standard documentation need to be confirmed with Chihade International, Inc. prior to order confirmation, as different customers—even in the same country—have different requirements.</p>

                <h2>Frozen Cheese</h2>
                <p>CII does not recommend shipping cheese frozen, other than mozzarella if manufactured approved. CII will not be responsible for any damage to frozen cheese products it has been requested to freeze. For best results with frozen cheese, the product should be defrosted slowly in a chilled environment.</p>

                <h2>Chilled Meats and Cheeses</h2>
                <p>CII recommends chilled meat and cheese be shipped by air due to short shelf-life. CII cannot be held responsible for product received with short shelf-life due to delays in shipments from shipping and trucking companies or other factors outside of CII control, which may lead to product arriving with short shelf-life.</p>

                <h2>Product Damage</h2>
                <p>Upon opening the door to the container, if there is any shifting or damage causing claims that could be in excess, an assessor approved by the freight company and the insurance company should be called to examine the load. Pictures must be provided prior to unloading the container.</p>

                <h2>Improper Dating or Labeling</h2>
                <p>Any errors in CII rework should be documented showing a picture of the incorrect ink-jetted or labeled product. CII cannot be held responsible for translations effected due to differences in dialect.</p>

                <h2>Temperature-Controlled Products</h2>
                <p>Any damage to product chilled or frozen due to a variance in temperature must be reported to CII within 2 days of receiving the product. A temperature recorder will be placed in each shipment and must be provided with any claim.</p>

                <h2>Container Dividers</h2>
                <p>Any temperature changes, fluctuations that occur when using a container divider are not covered by insurance or CII and at the sole risk of the purchaser. CII advises against using container dividers, as there is a high risk of temperature fluctuations.</p>

                <h2>Credits</h2>
                <p>Any credits given will be applied to a future order. At no time shall a payment be delayed due to a claim which has not been resolved with insurance or for any other reason. Any delay in payment is considered overdue and may affect future business and credit insurance company for collection.</p>

                <p><strong>Updated 1/1/2021</strong></p>
            </div>
        </div>
    );
}

export default Claims;
