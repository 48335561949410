import React from 'react';
import './Bribery.css';

function BriberyPolicy() {
    return (
        <div className="bpolicy-page">
            {/* Header Section */}
            <div>
                <h1>Chihade International, Inc. Bribery and Foreign Corrupt Practices Act Policy</h1>
                <p><strong>Policy Updated: November 17, 2015</strong></p>
            </div>

            {/* Introduction */}
            <div>
                <p>
                    As outlined in our Code of Conduct, Chihade International, Inc. (CII) operates with integrity and maintains a strict prohibition against all forms of bribery and corruption. This policy applies to all employees, officers, directors, and third parties acting on behalf of CII.
                </p>
            </div>

            {/* Prohibited Activities */}
            <div>
                <h2>Prohibited Activities</h2>
                <p>
                    CII prohibits offering, giving, soliciting, or receiving any form of bribe to secure an improper advantage in business dealings. This includes, but is not limited to:
                </p>
                <ul>
                    <li>Cash payments or equivalents</li>
                    <li>Gifts, hospitality, or entertainment exceeding reasonable and customary limits</li>
                    <li>Political or charitable contributions made to influence business decisions</li>
                </ul>
            </div>

            {/* Third-Party Interactions */}
            <div>
                <h2>Third-Party Interactions</h2>
                <p>
                    All third parties acting on behalf of CII must comply with this policy. It is the responsibility of the employee engaging with third parties to ensure that they understand and adhere to our anti-bribery standards.
                </p>
            </div>

            {/* Reporting Violations */}
            <div>
                <h2>Reporting Violations</h2>
                <p>
                    Any suspected violations of this policy must be reported immediately to the Compliance Department or through the anonymous reporting hotline. CII ensures confidentiality and prohibits retaliation against individuals who report concerns in good faith.
                </p>
            </div>

            {/* Training and Compliance */}
            <div>
                <h2>Training and Compliance</h2>
                <p>
                    All employees are required to participate in regular training on anti-bribery and corruption laws, including the Foreign Corrupt Practices Act (FCPA). CII monitors compliance through periodic audits and internal reviews.
                </p>
            </div>

            {/* Consequences of Non-Compliance */}
            <div>
                <h2>Consequences of Non-Compliance</h2>
                <p>
                    Failure to comply with this policy may result in disciplinary action, including termination of employment or contractual agreements. Additionally, individuals may face legal penalties, including fines and imprisonment, under applicable anti-bribery laws.
                </p>
            </div>
        </div>
    );
}

export default BriberyPolicy;
