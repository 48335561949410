import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Footer from './Footer';
import './Layout.css';
import logo from './images/ChihadeLogo_NoBkgd.png';
import logo45 from '../src/images/45 years.png';

function Layout() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const handleHashScroll = () => {
      if (location.hash) {
        const targetElement = document.querySelector(location.hash);
        if (targetElement) {
          const yOffset = -195;
          const yPosition = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: yPosition, behavior: 'smooth' });
        }
      }
    };
    handleHashScroll();
  }, [location]);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('.golden-nav') && !event.target.closest('.hamburger-icon')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const menuItems = [
    {
      label: 'About Us',
      path: '/about',
      dropdownItems: [
        { label: 'Our Mission', path: '/about#mission' },
        { label: 'History', path: '/about#history' },
        { label: 'Our Team', path: '/about#team' }
      ]
    },
    {
      label: 'Export Services',
      path: '/export',
      dropdownItems: [
        { label: 'Logistics & Export Services', path: '/export#logistics' },
        { label: 'Franchise Services', path: '/export#franchise' },
        { label: 'Distributor Partners', path: '/export#distributor' },
        { label: 'Retail & Supermarket', path: '/export#retail' }
      ]
    },
    {
      label: 'Warehouse Solutions',
      path: '/warehouse',
      dropdownItems: [
        { label: 'Our Facility', path: '/warehouse#facility' },
        { label: 'Warehouse Service', path: '/warehouse#services' },
        { label: 'Quality Control', path: '/warehouse#quality' }
      ]
    },
    {
      label: 'Products',
      path: '/products',
      dropdownItems: [
        { label: 'Vendor Partners', path: '/products#vendor-partners' },
        { label: 'Foodservice Products', path: '/products#foodservice-products' },
        { label: 'Retail Products', path: '/products#retail-products' },
        { label: 'Proteins', path: '/products#proteins' }
      ]
    }
  ];

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-bg"></div>
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Chihade Logo" className="App-logo" />
          </Link>
        </div>

        <div className="header-content">
          <h1 className="header-text">We Speak Food In Every Language</h1>
          <Link to="/contact" className="contact-us">CONTACT US</Link>
        </div>
        <div className="logo-45">
          <img src={logo45} alt="45 Years Logo" />
        </div>
        <div className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>

      <nav className={`golden-nav ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          {menuItems.map((item, index) => (
            <li 
              key={index} 
              className={`dropdown ${activeDropdown === index ? 'active' : ''}`}
              onClick={() => toggleDropdown(index)}
            >
              <Link to={item.path} onClick={() => setIsMenuOpen(false)}>
                {item.label}
              </Link>
              {item.dropdownItems && (
                <div className="dropdown-content">
                  {item.dropdownItems.map((dropItem, dropIndex) => (
                    <Link 
                      key={dropIndex}
                      to={dropItem.path}
                      onClick={() => {
                        setIsMenuOpen(false);
                        setActiveDropdown(null);
                      }}
                    >
                      {dropItem.label}
                    </Link>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>

      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;