import React, { useEffect } from 'react';
import './Warehouse.css';
import facilityImg1 from './images/warehouse solutions - our facility 1.png';
import facilityImg2 from './images/warehouse solutions - our facility 2.png';
import sample_Img from './images/sample_Img.png'
import warehouseImg1 from './images/Warehouse services - warehouse services 1.png';
import warehouseImg2 from './images/warehouse solutions - warehouse services 2.png';
import qualityImg from './images/warehouse services - quality control.png';
import bannerImage from './images/warehouse solutions - header.png';

function Export() {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const yOffset = -100; // Adjust offset for fixed header
                const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: yPosition, behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className="export-page">
            {/* Banner Section */}
            <div
                className="wbanner"
                style={{
                    backgroundImage: `url(${bannerImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '170%',
                    backgroundPosition: 'center',
                    height: '500px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    paddingBottom: '100px',
                    position: 'relative'
                }}
            >
                <h1
                    style={{
                        fontFamily: "'Quicksand', sans-serif",
                        color: 'white',
                        fontSize: '4.5rem',
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        position: 'absolute',
                        top: '55%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        zIndex: 1
                    }}
                >
                    WAREHOUSE SOLUTIONS
                </h1>
            </div>

            {/* Our Facility Section */}
            <div className="two-column-section" id="facility">
                <div className="column">
                    <h2>OUR FACILITY</h2>
                    <p>
                        With our modern 64,000 sq. ft. facility, we have the ability to handle all of our customers’ food export needs. Whether dry, chilled, or frozen, our experts in food handling maintain close control over quality and time-sensitive products. We have 7 lines running to insure product has appropriate language labels and ink-jetting to meet country requirements.
                    </p>
                    <p>
                        At our facility, extremely low turn-over of our employees makes for dedicated, knowledgeable, and experienced handlers, ensuring excellent care of goods and providing a valuable long-term solution for our customers.

                    </p>
                    <ul>
                        <li>8,000 sq. ft. Freezer</li>
                        <li>16,000 sq. ft. Chilled Storage</li>
                        <li>40,000 sq. ft. Dry Storage</li>
                        <li>Storage 22 Dock Doors Including 2 Cool Docks</li>

                    </ul>
                </div>
                <div className="column">
                    <div className="image-row">
                        <img
                            src={facilityImg1}
                            alt="Facility 1"
                            style={{ width: '90%', maxWidth: '400px', height: '50%' }} // Increased size
                        />
                        <img
                            src={facilityImg2}
                            alt="Facility 2"
                            style={{ width: '90%', maxWidth: '400px', height: '50%' }} // Increased size
                        />
                    </div>

                </div>
            </div>

            {/* Warehouse Services Section */}
            <div className="two-column-section" id="services">
                <div className="column">
                    <div className="image-row">
                        <img
                            src={warehouseImg1}
                            alt="Warehouse 1"
                            style={{
                                width: '90%',
                                maxWidth: '400px',
                                height: '50%'
                            }} // Increased size
                        />
                        <img
                            src={warehouseImg2}
                            alt="Warehouse 2"
                            style={{
                                width: '90%',
                                maxWidth: '400px',
                                height: '50%'
                            }} // Increased size
                        />
                    </div>

                </div>
                <div className="column">
                    <h2>WAREHOUSE SERVICES</h2>
                    <ul>
                        <li>3PL Services</li>
                        <li>Storage & Consolidation</li>
                        <li>Labeling & Translation</li>
                        <li>Reboxing & Kitting</li>
                    </ul>
                </div>
            </div>

            {/* Quality Control Section */}
            <div className="two-column-section" id="quality">
                <div className="column">
                    <h2>QUALITY CONTROL</h2>
                    <p>
                        Chihade International is an <span className="sql-certified">SQF Certified Facility</span>.
                        Safe Quality Food Program (SQF) is recognized by retailers and foodservice providers around the world who require a rigorous, credible food safety management system. What’s more, the SQF Program is recognized by the Global Food Safety Initiative (GFSI) and links primary production certification to food manufacturing, distribution and agent/broker management certification. SQF is recognized by retailers and foodservice providers around the world who require HACCP and ISO based food safety and quality management systems from their suppliers.
                    </p>
                    <ul>
                        <li>USDA on-site export inspections</li>
                        <li>Ingredient & dating verification</li>
                        <li>Proper storage & handling for frozen, chilled, and non-refrigerated foods</li>
                        <li>300KW Backup Diesel Generator</li>
                        <li>Export documentation prepared in-house</li>
                        <li>Label formatting and in-house printing</li>
                        <li>In-house foreign language translation, specialty labeling & dating checks</li>
                        <li>Safe Quality Food Program</li>
                    </ul>
                </div>
                <div className="column">
                    <img src={qualityImg} alt="Quality Control" className="qualityImg" />

                </div>
            </div>
        </div>
    );
}

export default Export;
