import React, { useEffect } from 'react';
import './Privacy.css';

function Privacy() {
    return (
        <div className="pexport-page">
            {/* Banner Section */}
            <div>
                <h1>Privacy Policy</h1>
                <p><strong>Chihade International, Inc.</strong></p>
                <p><strong>Privacy Policy</strong></p>
                <p><strong>11-4-13</strong></p>

                <h2>General</h2>
                <p>This Privacy and Security Policy (this “Privacy Policy”) explains how we collect, protect, use, and disclose the Personal Information (defined below), including, without limitation, any Personal Information gathered for and on behalf of Chihade, through any website operated by or on behalf of Chihade, including but not limited to, www.chihadeintl.com, together with any and all future websites that are operated by or on behalf of Chihade (together, the “Website”).</p>
                <p>“Chihade”, “we” and “our” refer to Chihade International, Inc. and its operating subsidiaries and affiliates that adopt this Privacy Policy. By visiting the Website or otherwise disclosing Personal Information to Chihade, you are accepting the practices outlined in this Privacy Policy, which is subject to modification as described below. It is your responsibility to review this Privacy Policy frequently and remain informed about any changes to it, so we encourage you to visit this page often.</p>
                <p>All Personal Information is collected in a fair and non-intrusive manner, with your voluntary consent. Personal Information is not accessible to anyone outside the specific function for which it is collected. Chihade respects the privacy of our customers, our employees, job applicants, and other visitors to our Website who may choose to provide Personal Information. We recognize the need for appropriate protections and management of the Personal Information that you provide to us. This Privacy Policy will assist you to understand what types of information we may collect, how that information may be used, and with whom the information may be shared.</p>

                <h2>Consent</h2>
                <p>By using www.chihadeintl.com, or participating in any program or service managed by Chihade, or otherwise disclosing Personal Information to Chihade, you consent to the data collection and use practices described in this Privacy Policy. If you have questions about this Privacy Policy or the protection of your information, please contact the Privacy Officer at privacyofficer@chihadeintl.com.</p>
                <p>SO THERE MAY BE NO DOUBT, BY SUBMITTING PERSONAL INFORMATION TO CHIHADE, AND/OR BY ACCESSING AND USING THE WEBSITE, YOU AGREE THAT CHIHADE MAY COLLECT, USE AND DISCLOSE SUCH PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY AND THE TERMS AND CONDITIONS AS PERMITTED OR REQUIRED BY LAW.</p>
                <p>In addition to consenting to this Privacy Policy and the Terms and Conditions, including any relevant supplemental policies, you expressly consent to Chihade sharing the information that you have provided, as described herein. You agree that Chihade and those with whom it shares information (the "Recipients") may disclose and transfer your information worldwide, including in and outside the United States for any purpose relating to Chihade’s operations and programs.</p>

                <h2>Types of Information We Collect</h2>
                <p><strong>Personal Information</strong> is any information concerning the personal or material circumstances of an identified or identifiable individual. An identifiable person is one who can be identified, directly or indirectly, by reference to a Social Security Number and/or Identification Number or to one or more factors specific to his physical, physiological, mental, economic, cultural or social identity.</p>
                <p>Personal Information shall include but is not limited to: name, home address, home zip code, home telephone number, mobile phone number, email address, Social Security Number and/or Identification Number, financial information and employment related information such as may be found on resumes, applications, background verification information, or in employment references.</p>

                <h2>How We Collect Information</h2>
                <p>Chihade may collect Personal Information from its Website users in several ways:</p>
                <ul>
                    <li><strong>Use of the Website:</strong> When you visit a Website we may ask you for Personal Information so that we can provide you with the products, services, or information that you have requested.</li>
                    <li><strong>Cookies and Internet Monitoring Technologies:</strong> We use data collection devices such as "cookies" on certain web pages to help analyze our web page flow and measure promotional effectiveness.</li>
                    <li><strong>Internet Protocol (IP) Address:</strong> Chihade may use your IP Address to help diagnose problems with Chihade’s server, to administer the Website and to maintain contact with you as you navigate through the Website.</li>
                    <li><strong>Aggregate Information:</strong> Chihade may undertake statistical and other summary analyses of the visitors' behaviors and characteristics.</li>
                </ul>

                <h2>How We Use Personal Information</h2>
                <p>We use your Personal Information to facilitate the services you request, evaluate prospective customers, suppliers and employees, and, if we so elect, to open, maintain and terminate customer accounts, supplier arrangements, or employment relationships.</p>

                <h2>Sharing or Disclosure of Personal Information</h2>
                <p>Chihade may share Personal Information with third parties as described below:</p>
                <ul>
                    <li><strong>Third Party Service Providers:</strong> We depend upon third parties and independent contractors to provide services in connection with our business operations.</li>
                    <li><strong>Corporate Transaction:</strong> Chihade reserves the right to disclose and/or transfer Personal Information to a third party in the event of a proposed or actual purchase, sale, lease, merger, amalgamation or any other type of acquisition, disposal, or financing of all or any portion of Chihade.</li>
                    <li><strong>Required by Law:</strong> Chihade may disclose Personal Information if required to do so by law or in the good faith belief that such action is necessary to conform to the edicts of the law or comply with legal process served on Chihade or its affiliates.</li>
                </ul>

                <h2>Security of Personal Information</h2>
                <p>We endeavor to protect your Personal Information using physical, electronic or procedural security measures appropriate to the sensitivity of the information in our control. These measures include safeguards to protect Personal Information against loss or theft, as well as unauthorized access, disclosure, copying, use, and modification.</p>

                <h2>Notification of a Security Breach</h2>
                <p>If Chihade or a Recipient is required to provide notice of unauthorized access of certain security systems, you agree that Chihade, or the Recipient, may do so by posting notice on the Website or sending notice to any email address Chihade or the Recipient has for you, in the good faith discretion of Chihade or the Recipient.</p>
            </div>
        </div>
    );
}

export default Privacy;
