import React, { useEffect } from 'react';
import './Products.css';
import vendorImg1 from './images/products - vendor partners 1.png';
import vendorImg2 from './images/products - vendor partners 2.png';
import vendorImg3 from './images/products - vendor partners 3.png';
import foodserviceImg from './images/products - foodservice products cii site.png';
import retailImg from './images/products - retail products.png';
import proteinsImg from './images/products - proteins.png';
import headerImage from './images/PRODUCTS PAGE PIC.png'

function Products() {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const yOffset = -100; // Adjust offset as needed
                const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: yPosition, behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className="products-page">
            {/* Banner Section */}
            <div className="pbanner">
                <h1>PRODUCTS</h1>
            </div>

            {/* Vendor Partners Section */}
            <div id="vendor-partners" className="two-column-section">
                <div className="column">
                    <h2>VENDOR PARTNERS</h2>
                    <p>
                        We work with over 300 vendors and supply thousands of products to our foodservice, franchise and retail partners. Our vendors appreciate our expertise and management of all aspects of export markets, including introducing product to new markets, managing day to day sales, and taking title to goods domestically.
                    </p>
                    <p>
                        Chihade International’s team of experts also manage shipping, marketing, and receiving payments for exports, adding value for our vendors and easing the complexities of export food shipments.
                    </p>
                    <ul>
                        <li>New customer acquisition</li>
                        <li>Consolidation and Labeling services</li>
                        <li>Taking title to goods domestically and financing international A/R</li>
                        <li>Product marketing and promotions</li>
                        <li>All aspects of exporting</li>
                    </ul>
                </div>
                
                    <div className="image-row">
                        <img src={vendorImg1} alt="Vendor 1" 
                        style={{
                            width: '90%',
                            maxWidth: '400px',
                            height: '50%'
                        }} />
                        <img src={vendorImg2} alt="Vendor 2" 
                        style={{
                            width: '90%',
                            maxWidth: '400px',
                            height: '50%'
                        }} />
                        <img src={vendorImg3} alt="Vendor 3" 
                        style={{
                            width: '90%',
                            maxWidth: '400px',
                            height: '50%'
                        }} />
                    </div>
                
            </div>

            {/* Foodservice Products Section */}
            <div id="foodservice-products" className="two-column-section reverse">
                <div className="p-two-column-column">
                    <img src={foodserviceImg} alt="Foodservice Products" />
                </div>
                <div className="column">
                    <h2>FOODSERVICE PRODUCTS</h2>
                    <p>
                        Chihade International sales force has a combined 100+ years working in the foodservice industry, supplying restaurants, hotels and cafes. We offer key volume food products that drive business and we have the ability to source unique products for particular needs. We work with the distributor to understand their needs and to have a common goal.
                    </p>
                    <p>
                        Chihade International Sales Staff has worked and lived in the market and
                        understands the market dynamics. We have sales managers residing in the market and able to communicate on your time zone.
                    </p>
                    <p>
                        Chihade International also works with your sales force to make targeted
                        customer calls. We are always looking to offer the newest product lines to enter the marketplace.
                    </p>
                </div>
            </div>

            {/* Retail Products Section */}
            <div id="retail-products" className="two-column-section">
                <div className="column">
                    <h2>RETAIL PRODUCTS</h2>
                    <p>
                        Chihade International has key direct retail manufacturer relationships offering the most competitive pricing. We are constantly on the lookout for new and unique product lines that have been successful in the U.S. We understand the organic and all natural niche markets that continue to grow.
                    </p>
                    <p>

                        We have the ability to take product photos and provide all required information for product registrations. We also understand the importance of receiving products with the maximum amount of remaining shelf life and we work with our suppliers to ensure we are shipping the freshest products possible.
                    </p>
                </div>
                <div className="p-two-column-column">
                    <img src={retailImg} alt="Retail Products" />
                </div>
            </div>

            {/* Proteins Section */}
            <div id="proteins" className="two-column-section reverse">
                <div className="p-two-column-column">
                    <img src={proteinsImg} alt="Proteins" />
                </div>
                <div className="column">
                    <h2>PROTEINS</h2>
                    <p>
                        Chihade International works with many of the largest beef, poultry and seafood packers in the USA as well as protein suppliers specializing in halal products. We have a USDA inspector on site and we have in depth knowledge of all import requirements in the countries we service
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Products;
