import React from 'react';
import { Link } from 'react-router-dom';
import logo from './images/ChihadeLogo_NoBkgd.png';
import locationIcon from './images/location icon.png';
import phoneIcon from './images/phone icon.png';
import emailIcon from './images/mail icon.png';
import './Footer.css';

function Footer() {
  return (
    <footer className="Footer">
      {/* Globe background as visual design */}
      <div className="footer-bg"></div>

      {/* Main footer content (on top of the globe) */}
      <div className="footer-content">
        <div className="footer-logo-address">
          {/* Logo to the left */}
          <img src={logo} alt="Chihade Logo" className="Footer-logo" />

          {/* Contact Information */}
          <div className="footer-info">
            {/* Location */}
            <p className="info-item">
              <img src={locationIcon} alt="Location" className="icon" />
              <span>Chihade International, Inc.</span>
            </p>
            <p className="info-item">
              <span>1025 Raco Court, Suite D</span>
            </p>
            <p className="info-item">
              <span>Lawrenceville, GA 30046 USA</span>
            </p>

            {/* Phone */}
            <p className="info-item">
              <img src={phoneIcon} alt="Phone" className="icon" />
              <span>+1 404-292-5033</span>
            </p>

            {/* Email */}
            <p className="info-item">
              <img src={emailIcon} alt="Email" className="icon" />
              <a href="mailto:info@chihadeintl.com">info@chihadeintl.com</a>
            </p>
          </div>
        </div>
      </div>

      {/* Yellow Bar at the very bottom */}
      <div className="footer-bottom">
        <p>
          &copy; 2024 Chihade International, Inc. All rights reserved.
        </p>
        <p className="footer-links">
          <Link to="/privacy" className="footer-link">Privacy Policy</Link>
          <span className="footer-separator"> | </span>
          <Link to="/claims" className="footer-link">Claims Policy</Link>
          <span className="footer-separator"> | </span>
          <Link to="/bribery" className="footer-link">Anti-Bribery Policy</Link>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
